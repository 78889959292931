export const SUCCESS = 'success'

export const EXIST = 'exist'
export const NOT_EXIST = 'not_exist'

export const CLUB_NOT_SELECT = 'club_not_select'
export const ACCESS_DENIED = 'access_denied'
export const PARAMETER_ERROR = 'parameter_error'
export const DATABASE_ERROR = 'database_error'
export const INCORRECT_VERIFICATION_CODE = 'incorrect_verification_code'
export const INVALID_MOBILE_NUM = 'invalid_mobile_num'
export const LOGIN_FAIL = 'login_fail'
export const SMS_SEND_FAIL = 'sms_send_fail'
export const DELETE_NOT_ALLOWED = 'delete_not_allowed'
export const SELF_REVOKE_FROM_EDIT_STAFF_NOT_ALLOWED = 'self_revoke_from_edit_staff_not_allowed'

export default {
  SUCCESS,
  EXIST,
  NOT_EXIST,
  CLUB_NOT_SELECT,
  ACCESS_DENIED,
  PARAMETER_ERROR,
  DATABASE_ERROR,
  INCORRECT_VERIFICATION_CODE,
  INVALID_MOBILE_NUM,
  LOGIN_FAIL,
  SMS_SEND_FAIL,
  DELETE_NOT_ALLOWED,
  SELF_REVOKE_FROM_EDIT_STAFF_NOT_ALLOWED
}
