<template>
<!--  机组管理页vue-->
  <div class="in-workbench">
    <el-row :gutter=20>
      <el-col :span=4>
        <el-button type="primary" class="btn-full" @click="newMachine">新增机组</el-button>
      </el-col>
    </el-row>
    <!-- 顶部操作栏结束，表格开始 -->
    <el-row :gutter=20>
      <el-col :span=24>
        <el-table :data="allMachine" border class="tableInWorkbench" height="calc(100vh - 180px)">
          <el-table-column prop="name" :min-width=150 label="机组名称"></el-table-column>
          <el-table-column prop="company" :min-width=120 label="所属公司"></el-table-column>
          <el-table-column prop="serviceDep" :min-width=120 label="所属服务部"></el-table-column>
          <el-table-column prop="centerStation" :min-width=120 label="所属中心站"></el-table-column>
          <el-table-column prop="station" :min-width=120 label="所属换热站"></el-table-column>
          <el-table-column prop="phone1" :min-width=120 label="联系电话"></el-table-column>
          <el-table-column prop="multiplyingPower" :min-width=120 label="电表倍率"></el-table-column>
          <el-table-column prop="createYear" :min-width=120 label="建设年份"></el-table-column>
          <el-table-column prop="remark" :min-width=200 label="备注" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column :resizable="false" align="center" :width=100 label="操作">
            <template slot-scope="scope">
              <el-link @click="editMachine(scope.row)">编辑</el-link>&nbsp;
              <el-link @click="deleteMachine(scope.row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 表格结束，机组编辑弹出框开始 -->
<!--    三元运算决定弹出框的标题-->
    <el-dialog :title="isNew ? '新增机组' : '编辑机组'" :visible.sync="machineEditorVisible" :append-to-body="true" width="50%" top="calc((100vh - 600px) / 2)" :center=true :close-on-click-modal=false>
      <el-form label-width="90px">
        <el-row>
          <el-col :xs=24 :sm=12>
            <el-form-item label="机组名称">
              <el-input v-model="editingMachine.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="所属公司">
              <el-input v-model="editingMachine.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="所属服务部">
              <el-input  v-model="editingMachine.serviceDep"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="所属中心站">
              <el-input v-model="editingMachine.centerStation"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="所属换热站">
              <el-input  v-model="editingMachine.station"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="联系电话">
              <el-input v-model="editingMachine.phone1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="电表倍率">
              <el-input v-model="editingMachine.multiplyingPower"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="建设年份">
              <el-input v-model="editingMachine.createYear"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="OPC前缀">
              <el-input v-model="editingMachine.opcPrefix"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="OPC后缀">
              <el-input v-model="editingMachine.opcPostfix"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span=24>
            <el-form-item label="备注">
              <el-input type="textarea" :rows="4" v-model="editingMachine.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveMachine">确定</el-button>
        <el-button type="danger" @click="machineEditorVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import permissions from "../../js/permissions";
import clientUtils from "../../js/clientUtils";
import api from "../../js/api";
import serverMessages from "../../js/serverMessages";
export default {
  name: "MachineManagement",
  data: function () {
    return {
      pagePermissionKey: permissions.MACHINE_MANAGEMENT,
      allMachine: [],
      editingMachine: {},
      machineEditorVisible: false,
      isNew: true
    }
  },
  methods: {
    newMachine: function () {
      this.isNew = true;
      this.machineEditorVisible = true;
      this.editingMachine = {
        id:0,
        centerStation: "",
        company: "",
        latitude: "0.0",
        longitude: "0.0",
        name: "",
        opcPostfix: "",
        opcPrefix: "",
        phone1: "",
        phone2: "",
        remark: "",
        serviceDep: "",
        station: "",
        sortId: 0
      };
    },
    //点击编辑弹出dialog
    editMachine: function (machine) {
      this.isNew = false;
      this.machineEditorVisible = true;
      this.editingMachine = JSON.parse(JSON.stringify(machine));
    },
    saveMachine: function () {
      let updatejosn = JSON.stringify(this.editingMachine);
      let url;
      console.log(updatejosn);
      //判断后端借口是新增机组还是编辑机组
      if (this.isNew){
         url ='/system/machine/add'
      }else {
         url = '/system/machine/modify'
      }
      const that =this;
      clientUtils.post(url, updatejosn, function (res) {
        if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
          // 编辑用户信息成功
          that.loadAllMachine();
          that.back();
        } else {
          clientUtils.unknownErrorAlert(that, res.data.msg)
        }
      })
    },
    back:function(){
      this.machineEditorVisible =false
    },
    deleteMachine: function (machine) {
      const that = this;
      this.$confirm('确定要删除 ' + machine.name + ' 吗？', clientUtils.Tip, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        // 确定删除
        clientUtils.get('/system/machine/delete/' + machine.id, function (res) {
          if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
            // 删除成功
            that.loadAllMachine();
            clientUtils.popSuccessMessage('机组删除成功！')
          } else {
            // clientUtils.unknownErrorAlert(that, res.data.msg)
          }
        })
      }).catch(() => { })
    },
    loadAllMachine: function () {
      const that = this;
      clientUtils.get(api.GET_ALL_MACHINE, function (res) {
        if (res.data) {
          that.allMachine = res.data;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('selectMenu', this.$route.path);
      this.loadAllMachine();
    })
  }
}
</script>

<style scoped>

</style>
