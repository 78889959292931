// 全局
export const NONE = '';

// 系统
// 人员管理
export const STAFF_MANAGEMENT = 'S_SM';
// 机组管理
export const MACHINE_MANAGEMENT = 'S_MM';

export default {
  NONE,
  STAFF_MANAGEMENT, MACHINE_MANAGEMENT
}
